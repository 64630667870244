<script setup lang="ts">
interface Props {
        container?: string;
        alignment?: 'top-full' | 'top-0' | 'bottom-0' | 'bottom-full';
        position?: 'left-full' | 'left-0' | 'right-0' | 'right-full' | 'center-x';
        overlay?: boolean;
        maxHeight?: string;
        withoutMaxHeight?: boolean;
        relativeDivClass?: string;
    }
    const { $theme } = useNuxtApp();
    const emit = defineEmits(['shown', 'hidden']);
    
    const props = defineProps({
  container: null,
  alignment: null,
  position: null,
  overlay: { type: Boolean },
  maxHeight: null,
  withoutMaxHeight: { type: Boolean },
  relativeDivClass: null
});
    const submenu = ref(null);
    const submenuContainer = ref(null);
    const isDisplayed = ref(false);
    const submenuClass = computed(() => {
        let maxHeight = '';
        if (!props.withoutMaxHeight) {
            maxHeight = `${props.maxHeight || 'max-h-[300px]'} overflow-y-auto nice-scrollbar `;
        }
        return `${props.container ? props.container : $theme('submenu.container.large')} ${props.position || 'left-0'} ${maxHeight} ${props.alignment || ' top-full'}`;
    });
    const show = () => {
        if (isDisplayed.value) {
            return;
        }
        isDisplayed.value = true;
        emit('shown');
        nextTick(() => {
            submenuContainer.value?.focus();
        });
    };
    const hide = () => {
        if (!isDisplayed.value) {
            return;
        }
        isDisplayed.value = false;
        emit('hidden');
    };
    const toggle = () => {
        isDisplayed.value ? hide() : show();
    };
    defineExpose({
        isDisplayed,
        show,
        hide,
        toggle,
    });
</script>

<template>
    <div
        ref="submenu"
        class="relative"
        :class="relativeDivClass ?? ''"
    >
        <slot
            :hide="hide"
            :show="show"
            :toggle="toggle"
            :is-displayed="isDisplayed"
        ></slot>
        <transition
            :enter-active-class="$theme('submenu.transition.enter-active-class')"
            :enter-from-class="$theme('submenu.transition.enter-from-class')"
            :enter-to-class="$theme('submenu.transition.enter-to-class')"
            :leave-active-class="$theme('submenu.transition.leave-active-class')"
            :leave-from-class="$theme('submenu.transition.leave-from-class')"
            :leave-to-class="$theme('submenu.transition.leave-to-class')"
        >
            <div
                v-if="isDisplayed"
                ref="submenuContainer"
                :class="`${submenuClass} mt-1`"
                :tabindex="isDisplayed ? 100 : -1"
                @keydown.esc.stop="hide()"
            >
                <slot
                    name="submenu"
                    :hide="hide"
                ></slot>
            </div>
        </transition>
        <transition
            enter-active-class="transition-all duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-all duration-200 ease-out delay-150"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="isDisplayed"
                :class="[$theme('submenu.overlay.base'), overlay ? 'bg-[#0a183a45] backdrop-blur-[1px]' : '']"
                @click.stop.prevent="hide()"
                data-role="submenu-overlay"
            ></div>
        </transition>
    </div>
</template>
